<template>
  <div>
    <Header />

    <section class="guide-dem-header">
      <div class="h2">
        <router-link :to="{ name: 'guide-dem-list' }" rel="canonical">
          Location-gardemeuble.fr : Guide Déménagement
        </router-link>
      </div>

      <div class="guide-center">
        <h1>
          {{ this.current.name }}
        </h1>
        <p>
          {{ this.current.text }}
        </p>
        <div>
          {{ this.current.sub }}
        </div>
      </div>

      <nav v-if="this.store.getters.isDesktop">
        <router-link v-for="cat in this.categories" :to="{ name: 'guide-dem-category', params: { slug: cat.slug, category: cat.id } }">
          {{ cat.name }}
        </router-link>
      </nav>

      <div class="cat-selector" v-if="this.store.getters.isMobile">
        <label for="cat">Sélectionnez une catégorie :</label>
        <select id="cat" @change="this.onCategoryChange($event)">
          <option value="guide-demenagement-sommaire">
            Sommaire du guide
          </option>
          <option v-for="cat in this.categories" :value="cat.id" :selected="cat.id === this.current.id">
            {{ cat.name }}
          </option>
        </select>
        <br><br>
      </div>
    </section>

    <div class="articles-container-list">
      <section>
        <h2 class="title">
          {{ this.current.list_text }}
        </h2>
        <article v-for="article in this.current.articles" style="background: white;">
          <a :href="article.url">
            <p class="title">
              {{ article.title }}
            </p>
            <a :href="article.url" class="img" v-if="article?.img?.url">
              <img :src="article?.img?.url ?? 'https://www.location-gardemeuble.fr/img/no-img.gif'" :alt="article.title" width="250" height="150" />
            </a>
            <p class="text" v-html="article.extract"></p>
            <p class="read">
              Lire la suite
              <font-awesome-icon icon="eye"></font-awesome-icon>
            </p>
          </a>
        </article>
      </section>

      <aside v-if="this.store.getters.isDesktop">
        <div class="embedded-search">
          <h3 v-html="this.current.search_text ?? 'Trouvez un garde meuble au meilleur prix'"></h3>
          <SearchBar />
        </div>
        <hr>
        <div v-html="this.current.right_text?.replaceAll( 'https://www.location-gardemeuble.fr/img/upload/', 'https://api.location-gardemeuble.fr/img/upload/' )"></div>
      </aside>
    </div>

    <Footer />
  </div>
</template>

<script>
import { Options, setup, Vue } from "vue-class-component";
import { computed, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import { useMeta } from "vue-meta";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

library.add( faEye );

@Options( {
  components : {
    FontAwesomeIcon,
    Header    : defineAsyncComponent( () => import("@/components/Header.vue") ),
    Footer    : defineAsyncComponent( () => import("@/components/Footer.vue") ),
    SearchBar : defineAsyncComponent( () => import("@/components/SearchBar.vue") )
  }
} )
export default class extends Vue
{
  store = useStore( key );

  categories = [];
  current    = [];

  meta = setup( () => useMeta( computed( () => {
    return {
      title : [{
        content : "Guide du déménagement - " + this.current.name,
        vmid    : "title",
        name    : "title"
      }],
      description : {
        content : this.current.sub ?? "Le guide du déménagement de Location-gardemeuble.fr, tout savoir sur comment bien déménager sans s'embêter !",
        vmid    : "description",
        name    : "description"
      }
    };
  } ) ) );

  onCategoryChange( event )
  {
    // Find cateogory by id from this.categories
    let category = this.categories.find( cat => cat.id === parseInt( event.target.value ) );

    if( event.target.value === "guide-demenagement-sommaire" )
    {
      // Redirect to guide
      this.$router.push( { name: "guide-dem-list" } );
      return;
    }

    // Redirect to category
    this.$router.push( { name: "guide-dem-category", params: { slug: category.slug, category: category.id } } );
  }

  async getCategories()
  {
    let request = await fetch( this.store.state.api + "guide/demenagement/categories", {
      method  : "GET",
      cache   : "no-cache",
      headers : {
        "Content-Type" : "application/json",
        "Accept"       : "application/json"
      }
    } );

    let json        = await request.json();
    this.categories = json.data;
  }

  async getCategory()
  {
    let request = await fetch( this.store.state.api + "guide/demenagement/categories/" + this.$route.params.category, {
      method  : "GET",
      cache   : "no-cache",
      headers : {
        "Content-Type" : "application/json",
        "Accept"       : "application/json"
      }
    } );

    let json     = await request.json();
    this.current = json.data;
    this.store.state.current = json.data;
  }

  async mounted()
  {
    try
    {
      await this.getCategories();
      await this.getCategory();
    }
    catch( e )
    {
      console.error( e );
      // TODO : Better error handling
    }

    setTimeout( () => {
      this.store.state.loading = false;

      // Remove div#preload from DOM
      let preload = document.getElementById( "preload" );
      if( preload )
      {
        window.scrollTo( { top : 0 } );
        preload.remove();
      }
    }, this.store.state.delay );  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";
</style>
